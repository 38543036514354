import { useQuery, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { cartApi } from "shared/api/cart";
import { QueryKeys } from "shared/api/keys";

let refetchHandle: NodeJS.Timeout | null = null;

export const useIndexCart = () => {
    const client = useQueryClient();

    return useQuery([QueryKeys.CART_INDEX], () => cartApi.indexCart(), {
        refetchOnWindowFocus: true,
        onSuccess: (data) => {
            !!refetchHandle && clearTimeout(refetchHandle);

            refetchHandle = setTimeout(
                () => client.refetchQueries([QueryKeys.CART_INDEX]),
                -dayjs().diff(data.expires_at, "milliseconds")
            );
        },
    });
};
