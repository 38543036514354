import Axios from "axios";
import {
    authUserInterceptor,
    deviceIdUserInterceptor,
    fileInterceptor,
    guestCartInterceptor,
    loggerInterceptor,
    loggerResponseInterceptor,
    prepareQueryInterceptor,
    revalidationTokenServerSideInterceptor,
} from "./interceptors/request";
import { responderInterceptor } from "./interceptors/response";
import {
    defaultHeaders,
    errorLogger,
    errorResponseInterceptor,
    refreshInstance,
    whenCartRoutes,
    whenDownloadingFiles,
    whenRestoringSession,
} from "./helpers";

export const instance = Axios.create({
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    headers: defaultHeaders,
    responseType: "json",
});

/**
 *  Данный интерцептор будет использоваться на основном проекте.
 */
instance.interceptors.request.use(authUserInterceptor, errorLogger);
instance.interceptors.request.use(authUserInterceptor, errorLogger);
/**
 *  ServerSide interceptor for logging
 */
instance.interceptors.request.use(loggerInterceptor, errorLogger, {
    runWhen: () => typeof window === "undefined",
});
/**
 *  ServerSide response interceptor for logging
 */
instance.interceptors.response.use(loggerResponseInterceptor, errorResponseInterceptor);

/**
 *  ServerSide interceptor for Throttle And SSG/ISR
 */
instance.interceptors.request.use(revalidationTokenServerSideInterceptor, errorLogger, {
    runWhen: () => typeof window === "undefined",
});

/**
 *  Также он будет использоваться при восстановлении сессии
 */
instance.interceptors.request.use(authUserInterceptor, errorLogger, {
    runWhen: whenRestoringSession,
});

/**
 * Данный интерцептор обрабатывает все объекты в query, переводя их в удобную для BackEnd-а структуру
 */
instance.interceptors.request.use(prepareQueryInterceptor, errorLogger);

/**
 * Данный интерцептор при рефреше токенов. Это копия instance. Разделение сделано для исключения бага с цикличной зависимостью при обращаении к instance
 */
refreshInstance.interceptors.request.use(authUserInterceptor, errorLogger);

/**
 *  Данный интерцептор будет использоваться при загрузке файлов.
 */
instance.interceptors.request.use(fileInterceptor, errorLogger, { runWhen: whenDownloadingFiles });

/**
 * Данный интерцептор будет использоваться для роутов корзины.
 * Он добавляет в query-params генерируемый на бэке ID устройства для привязки корзины
 */
instance.interceptors.request.use(guestCartInterceptor, errorLogger, { runWhen: whenCartRoutes });

/**
 *  Интерцептор обрабатывающий все респонсы
 */
instance.interceptors.response.use(responderInterceptor, errorResponseInterceptor);

/**
 * Для всех запросов отправляй deviceId
 */
instance.interceptors.request.use(deviceIdUserInterceptor, errorLogger);
