import { BaseApi } from "shared/api/baseApi";
import {
    $CartCount,
    $CartGuestId,
    $CartIndex,
    $GetShippingMethodCityResponse,
    $ShippingMethodGroupsResponse,
    $UpdateCartItem,
    $UpdateCartRequest,
    CreateCartItem,
    GetShippingMethodCityResponse,
    UpdateCartItemRequest,
    UpdateCartRequest,
} from "shared/api/cart/types";
import { $AdaptedResponse } from "shared/api/types";

export class CartApi extends BaseApi {
    async indexCart() {
        return $AdaptedResponse($CartIndex).parse(await this.instance.get(`/cart`));
    }
    async showCartCount() {
        return $AdaptedResponse($CartCount).parse(await this.instance.get(`/cart/count`));
    }
    async updateCart(request: UpdateCartRequest) {
        return await this.instance.put(`/cart`, $UpdateCartRequest.parse(request));
    }
    async createCartItem(newItem: CreateCartItem) {
        await this.instance.post(`/cart-items`, newItem);
    }
    async updateCartItem({ cartItemId, changedItem }: UpdateCartItemRequest) {
        await this.instance.put(`/cart-items/${cartItemId}`, $UpdateCartItem.parse(changedItem));
    }
    async removeCartItem(id: number) {
        await this.instance.delete(`/cart-items/${id}`);
    }
    async removeCart() {
        await this.instance.delete(`/cart/clear`);
    }
    async showNewCartId() {
        return $AdaptedResponse($CartGuestId).parse(await this.instance.get("/cart/uuid"));
    }
    async getShippingMethods() {
        return $AdaptedResponse($ShippingMethodGroupsResponse).parse(
            await this.instance.get("/shipping-method-groups")
        );
    }
    async getCities({ id, ...data }: { id: string; q: string; }): Promise<GetShippingMethodCityResponse> {
        return $AdaptedResponse($GetShippingMethodCityResponse).parse(
            await this.instance.post(`/shipping-methods/${id}/setting/getCities`, data)
        );
    }
    async getTerminals({ id, ...data }: { id: string; cityId: string; }): Promise<GetShippingMethodCityResponse> {
        return $AdaptedResponse($GetShippingMethodCityResponse).parse(
            await this.instance.post(`/shipping-methods/${id}/setting/getTerminals`, data)
        );
    }
    async createOrder({ comment }: { comment: string; }) {
        return this.instance.post("/orders", { comment });
    }
}
