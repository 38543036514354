import { PropsWithChildren, useMemo, useState } from "react";
import { CartContext } from "entities/cart/constants";
import { CartError, CartErrorType } from "entities/cart/types";

export const CartProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [cartErrors, setCartErrors] = useState<CartError[]>([]);
    const [comment, setComment] = useState<string>("");

    const addCartError = (newError: CartError) => {
        if (!cartErrors.some((error) => error.cartItemId === newError.cartItemId)) {
            setCartErrors((prev) => [...prev, newError]);
        }
    };

    const removeCartError = (id: CartError["cartItemId"]) => {
        const updatedErrors = cartErrors.filter((error) => error.cartItemId !== id);
        setCartErrors(updatedErrors);
    };

    const setMaxQuantityError = ({ id, hasError }: { id: number; hasError: boolean }) =>
        hasError ? addCartError({ cartItemId: id, typeError: CartErrorType.MAX_QUANTITY_ERROR }) : removeCartError(id);

    const hasMaxQuantityError = cartErrors.some((error) => error.typeError === CartErrorType.MAX_QUANTITY_ERROR);
    const context = useMemo(() => {
        return {
            hasMaxQuantityError,
            setMaxQuantityError,
            comment,
            setComment,
        };
    }, [hasMaxQuantityError, setMaxQuantityError, comment, setComment]);

    return <CartContext.Provider value={context}>{children}</CartContext.Provider>;
};
