import { useEffect } from "react";
import { ModalsProvider } from "@mantine/modals";
import { NotificationsProvider } from "@mantine/notifications";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppProps } from "next/app";
import { JivoChatProvider } from "providers/JivoChatProvider";
import {
    AuthProvider,
    QueryParamProvider,
    QueryProvider,
    ThemeProvider,
    YandexMetricaProvider,
} from "src/application/providers";
import "shared/utils/toNumber";
import "../application/styles/reset.css";

export default function App({ Component, pageProps }: AppProps) {
    useEffect(() => {
        console.warn(process.env.NODE_ENV);
    }, []);
    return (
        <YandexMetricaProvider>
            <JivoChatProvider />
            <ThemeProvider>
                <QueryProvider pageProps={pageProps}>
                    <NotificationsProvider position="top-right" containerWidth={500} limit={1} zIndex={1000}>
                        <ModalsProvider>
                            <AuthProvider>
                                <QueryParamProvider>
                                    <Component {...pageProps} />
                                </QueryParamProvider>
                            </AuthProvider>
                            <ReactQueryDevtools initialIsOpen={false} />
                        </ModalsProvider>
                    </NotificationsProvider>
                </QueryProvider>
            </ThemeProvider>
        </YandexMetricaProvider>
    );
}
