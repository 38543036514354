export enum CartErrorType {
    MAX_QUANTITY_ERROR = "maxQuantityError",
}

export type CartContextValue = {
    setMaxQuantityError: (value: { id: number; hasError: boolean; }) => void;
    setComment: (value: string) => void;
    comment: string;
    hasMaxQuantityError: boolean;
};

export type CartError = { cartItemId: number; typeError: CartErrorType; };
