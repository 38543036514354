import { ReactText } from "react";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import "react-toastify/dist/ReactToastify.css";

export type FormErrorResponse = {
    message: string;
    errors: {
        [key: string]: string[];
    };
};

export enum ToastType {
    INFO = "info",
    SUCCESS = "success",
    ERROR = "error",
    DARK = "dark",
    WARN = "warning",
}

export const isErrorsObject = (error?: AxiosError): error is AxiosError<FormErrorResponse> => {
    const errors = (error as AxiosError<FormErrorResponse>).response?.data?.errors;

    return typeof errors === "object";
};

export const isErrorsArray = (error?: AxiosError): error is AxiosError<FormErrorResponse> => {
    return (error as AxiosError<FormErrorResponse>).response?.data?.errors?.[0] !== undefined;
};

export const isMessageError = (error: AxiosError): error is AxiosError<FormErrorResponse> => {
    return (error as AxiosError<FormErrorResponse>).response?.data.message !== undefined;
};

// TODO: починить это: сейчас не работает потому что нет рабочих тостов
// смотри файл src/application/config/react-toastify/NotificationsProvider.tsx
export const handleShowToast = (error: AxiosError) => {
    const isValidationError = error.response?.status === 422;

    if ((isErrorsArray(error) || isErrorsObject(error)) && isValidationError) {
        //обработка ошибок валидации форм (несколько ошибок)
        Object.values(error.response?.data?.errors ?? {}).forEach((errorItem) => {
            return createNotification(ToastType.ERROR, errorItem[0]);
        });
    }
    if (!isValidationError && error.response?.data && isMessageError(error)) {
        //показываем более детальный текст ошибки, который выдает наш бэк
        return createNotification(ToastType.ERROR, error.response.data.message);
    }

    if (!isValidationError) {
        //показываем дефолтный текст ошибки
        return createNotification(ToastType.ERROR, error.message);
    }
};

export const createNotification = (type: ToastType, message: string): ReactText => {
    return toast[type](message);
};
