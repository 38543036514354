import { FC, memo } from "react";
import Image, { ImageProps } from "next/image";
import PlaceHolder from "./placeholder.png";

interface TPictureProps extends Omit<ImageProps, "src"> {
    src: ImageProps["src"] | undefined;
}

export const Picture: FC<TPictureProps> = memo(({ src = PlaceHolder.src, alt, style, title, ...props }) => {
    return (
        <Image
            src={src}
            style={{
                ...style,
                objectFit: "contain",
            }}
            overrideSrc={src as string}
            alt={alt}
            title={title ?? alt}
            {...props}
        />
    );
});

Picture.displayName = "Picture";
