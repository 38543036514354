import { createContext, useContext } from "react";
import { CartContextValue } from "./types";

export const CartContext = createContext<CartContextValue>({
    setMaxQuantityError: () => undefined,
    comment: '',
    setComment: () => undefined,
    hasMaxQuantityError: false,
});

export const useCartContext = () => useContext(CartContext);
